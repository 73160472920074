import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';
const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        "unitmeasure",
        () => import('./unit-measure/unit-measure.module').then(m => m.UnitMeasureModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "productbyactivity",
        () => import('./product-by-activity/product-by-activity.module').then(m => m.ProductByActivityModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "eucountry",
        () => import('./eu-country/eucountry.module').then(m => m.EUCountryModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "province",
        () => import('./province/province.module').then(m => m.ProvinceModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "customsoffice",
        () => import('./customs-office/customs-office.module').then(m => m.CustomsOfficeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "transportmode",
        () => import('./transport-mode/transport-mode.module').then(m => m.TransportModeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "trackingintradata",
        () => import('./tracking-intra-data/tracking-intra-data.module').then(m => m.TrackingIntraDataModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "deliverycondition",
        () => import('./delivery-condition/delivery-condition.module').then(m => m.DeliveryConditionModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "paymentmode",
        () => import('./payment-mode/payment-mode.module').then(m => m.PaymentModeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "provisionmode",
        () => import('./provision-mode/provision-mode.module').then(m => m.ProvisionModeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "combinednomenclature",
        () => import('./combined-nomenclature/combined-nomenclature.module').then(m => m.CombinedNomenclatureModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "transactionnature",
        () => import('./transaction-nature/transaction-nature.module').then(m => m.TransactionNatureModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "operationtype",
        () => import('./operation-type/operation-type.module').then(m => m.OperationTypeModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        "country",
        () => import('./country/country.module').then(m => m.CountryModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    // TODO Tommy: si potrebbe creare una custom preloadin strategy per precaricare solo le rotte offline
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
